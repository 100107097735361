.step-indicator {
  width: 100%;
  padding: 162px 20px 20px;
  @media (max-width: 832px) {
    padding: 30px 20px;
  }
  .step-indicator__container {
    display: flex;
    flex-direction: column;
    row-gap: 68px;
    position: relative;
    .step-indicator-img {
      position: absolute;
      bottom: -260px;
      right: -50px;
      @media (max-width: 1370px) {
        display: none;
      }
    }
    .step-items {
      display: flex;
      flex-direction: column;
      row-gap: 43px;
      justify-content: center;
      align-items: center;
      
      div:nth-child(even) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        column-gap: 101px;
        @media (max-width: 1300px) {
          flex-direction: column;
        }
        div:first-child {
          position: relative;
          img:last-child {
            width: 90%;
          }
          .product-item {
            position: absolute;
            right: 50px;
            bottom: 60px;
            @media (max-width: 1300px) {
              display: none;
            }
          }
          .step-item {
            position: absolute;
            right: 65px;
            max-width: 166px;
            height: 226px;
            border-radius: 12px;
            background: #fff;
            box-shadow: 0px 40px 100px -25px rgba(0, 0, 0, 0.12);
            display: flex;
            flex-direction: column;
            padding: 20px;
            justify-content: center;
            align-items: center;
            @media (max-width: 1300px) {
              display: none;
            }
            button {
              width: 126px;
              height: 86px;
              flex-shrink: 0;
              border-radius: 12px;
              background: rgb(245, 71, 72, 0.1);
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 26px;
              img {
                max-width: 59px;
                height: 59px;
              }
              @media (max-width: 988px) {
                padding: 24px 20px;
              }
            }

            span {
              margin-bottom: 8px;
              color: #2e2e2e;
              text-align: center;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 18px; /* 112.5% */
              letter-spacing: -0.16px;
            }
            p {
              color: #676767;
              text-align: center;
              font-family: Inter;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 160% */
              max-width: 125px;
            }
          }
        }
        div:nth-child(2) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;

          img {
            position: absolute;
            right: -750px;
            top: 40px;
            @media (max-width: 1300px) {
              display: none;
            }
          }
          span {
            color: #ffc700;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.18px;
            margin-bottom: 12px;
          }
          h2 {
            color: #2e2e2e;
            font-family: Poppins;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 54px; /* 135% */
            max-width: 566px;
            margin-bottom: 6px;
            @media (max-width:1024px) {
              max-width: 100%;
            }
          }
          p {
            color: #676767;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 166.667% */
            max-width: 519px;
            margin-bottom: 30px;
             @media (max-width:1024px) {
              max-width: 100%;
            }
          }
        }
      }

      div:nth-child(odd) {
        display: flex;
        justify-content: space-between;
        column-gap: 101px;
        align-items: center;
        @media (max-width: 1300px) {
          flex-direction: column;
        }
        div:first-child {
          position: relative;
          img {
            width: 90%;
          }
          .product-item {
            position: absolute;
            right: -20px;
            top: 90px;
            @media (max-width: 1300px) {
              display: none;
            }
          }
        }

        div:nth-child(2) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          img {
            top: -140px;
            right: -140px;
            position: absolute;
          }
          span {
            color: #ffc700;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.18px;
            margin-bottom: 12px;
          }
          h2 {
            color: #2e2e2e;
            font-family: Poppins;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 54px; /* 135% */
            max-width: 474px;
            margin-bottom: 6px;
             @media (max-width:1024px) {
              max-width: 100%;
            }
          }
          p {
            color: #676767;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 166.667% */
            max-width: 421px;
            margin-bottom: 30px;
             @media (max-width:1024px) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
