.help {
  max-width: 260px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  .help-location {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-top:1px solid rgba(224, 225, 226, 0.3);
    padding-top: 28px;
    .help-active {
      display: flex;
      padding: 4px;
      align-items: center;
      column-gap: 12px;
      width: 100%;
      max-width: 220px;
      height: 54px;
      flex-shrink: 0;
      border-radius: 15px;
      background: var(--black-amp-white-white, #fff);
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      border-radius: 15px;
      background: var(--black-amp-white-white, #fff);
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      color: var(--Gray-Gray-700, #2d3748);
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      img {
        border-radius: 12px;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        padding: 7.5px;
      }
      
        
      
    }
    a {
      display: flex;
      column-gap: 12px;
      max-width: 220px;
      width: 100%;
      height: 54px;
      flex-shrink: 0;
      align-items: center;
      color: var(--Gray-Gray-400, #a0aec0);
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 18px */
      img {
        padding: 8px;
        border-radius: 12px;
        background: var(--black-amp-white-white, #fff);
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      }

      &:first-child {
        img {
          background-color: #f00;
        }
      }

        
    }
  }

  .help-payment {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    margin: 36px 0;
    width: 100%;
    .help-payment_item {
      width: 216px;
      height: 80px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        h3 {
          color: var(--Gray-Gray-400, #a0aec0);
          font-family: Helvetica;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 18px */
          margin-bottom: 1px;
        }

        p {
          color: var(--Gray-Gray-700, #2d3748);
          font-family: Helvetica;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 25.2px */
          span {
            color: var(--Green-Green-400, #48bb78);
            font-family: Helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
          }
        }
      }

      img {
        border-radius: 12px;
        background: #f00;
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
        padding: 11.25px;
      }
    }
  }
  .help-info {
    padding: 16px;
    width: 234px;
    height: 170px;
    flex-shrink: 0;
    border-radius: 15px;
    background-image: url("../../../public/img/subback.png");
    img {
      width: 37.569px;
      height: 35.103px;
      flex-shrink: 0;
      border-radius: 12px;
      background: var(--black-amp-white-white, #fff);
    }
    h4 {
      margin-top: 21.56px;
      display: flex;
      width: 112.799px;
      height: 17.471px;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      color: var(--black-amp-white-white, #fff);
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
    p {
      color: var(--black-amp-white-white, #fff);
      font-family: Helvetica;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 18px */
    }
    button {
      border-radius: 12px;
      background: var(--black-amp-white-white, #fff);
      color: var(--Gray-Gray-700, #2d3748);
      text-align: center;
      font-family: Helvetica;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 15px */
      display: flex;
      width: 87.5px;
      height: 15px;
      flex-direction: column;
      justify-content: center;
      display: flex;
      width: 205.326px;
      height: 42.402px;
      padding: 0px 8px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin-top: 12px;
    }
  }
  @media (max-width: 988px) {
    display: none;
  }
}
