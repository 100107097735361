.tariff {
  width: 100%;
  padding: 32px 20px;

  .tariff__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .tariff-image {
      img:nth-child(1) {
        position: absolute;
        left: -90px;
        top: 0;
        transform: rotate(140deg);

        @media (max-width: 1153px) {
          display: none;
        }
      }

      img:nth-child(2) {
        position: absolute;
        right: -80px;
        bottom: 320px;

        @media (max-width: 1153px) {
          display: none;
        }
      }
    }

    h3 {
      width: 658px;
      color: #292929;
      text-align: center;
      font-family: Inter;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: -1px;
      margin-bottom: 18px;
    }

    p {
      max-width: 543.382px;
      color: #5b5a62;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.4px;
      
    }

    .tariff__items {
      display: flex;
      gap: 31px;
      margin-top: 44px;

      @media (max-width: 915px) {
        flex-direction: column;
        align-items: center;
      }

      .tariff__items-pro {
        background-color: #000;
        padding: 20px 41px;
        border-radius: 16px;
        height: 780px;

        p:nth-child(1) {
          display: flex;
          column-gap: 50px;
          align-items: center;
          color: #fff;
          font-family: Outfit;
          font-size: 44px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -1px;
          margin: 23px 0;

          @media (max-width: 458px) {
            font-size: 27px;
          }

          span {
            color: #fff;
            font-family: Outfit;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            display: flex;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #fff;
            background: rgba(255, 255, 255, 0.08);
          }
        }

        p:nth-child(2) {
          color: #fff;
          font-family: Outfit;
          font-size: 24px;
          text-align: left;
          font-style: normal;
          font-weight: 300;
          line-height: 32px;
          /* 133.333% */
          max-width: 380px;

          @media (max-width: 458px) {
            font-size: 18px;
          }
        }

        h5 {
          color: #fff;
          font-family: Outfit;
          font-size: 60px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1px;
          display: flex;
          align-items: flex-end;
          column-gap: 20px;

          @media (max-width: 458px) {
            font-size: 46px;
          }

          span {
            margin-bottom: 12px;
            color: #fff;
            font-family: Outfit;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;

            @media (max-width: 458px) {
              font-size: 18px;
            }
          }
        }

        div {
          width: 380px;
          border: 1px solid #e7ebff;
          margin: 28px 0;

          @media (max-width: 458px) {
            width: 280px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          row-gap: 12px;

          li {
            display: flex;
            align-items: center;
            column-gap: 16px;
            color: #fff;
            font-family: Outfit;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            /* 166.667% */
            @media (max-width:458px) {
              font-size: 18px;
            }

          }
        }

        button {
          margin: 24px 0;
          display: flex;
          width: 370px;
          padding: 12px 44px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 12px;
          border: 1px solid #f00;
          background: #f00;
          box-shadow: 0px 8px 36px 0px rgba(27, 34, 60, 0.16);
          color: #fff;
          font-family: Outfit;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;

          /* 166.667% */
          @media (max-width: 458px) {
            width: 280px;
            font-size: 18px;
          }
        }
      }

      .tariff__items-starter {
        border-radius: 26px;
        border: 1px solid #e7ebff;
        background: #fff;
        box-shadow: 0px 26px 40px 0px rgba(188, 202, 255, 0.13);
        padding: 40px 15px 18px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        h4 {
          color: #000;
          font-family: Outfit;
          font-size: 44px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -1px;
          margin-bottom: 14px;
          @media (max-width: 458px) {
            font-size: 27px;
          }
        }

        p {
          color: #797878;
          font-family: Outfit;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 32px;
          max-width: 359px;
          margin-bottom: 24px;
          @media (max-width: 458px) {
            font-size: 18px;
            margin-bottom: 2px;
          }
          
        }

        h5 {
          display: flex;
          align-items: flex-end;
          color: #000;
          font-family: Outfit;
          font-size: 60px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1px;
          column-gap: 14px;
          padding-bottom: 28px;
          border-bottom: 2px solid #E7EBFF;
          margin-bottom: 28px;
          padding-top: 34px;
          @media (max-width: 458px) {
            font-size: 46px;
            margin-top: 0;
          }

          span {
            margin-bottom: 14px;
            color: #797878;
            font-family: Outfit;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            @media (max-width: 458px) {
              font-size: 18px;
            }
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          margin-bottom: 37px;

          li {
            display: flex;
            align-items: center;
            color: #000;
            column-gap: 14px;
            font-family: Outfit;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            @media (max-width:458px) {
              font-size: 18px;
            }
          }
        }

        button {
          border-radius: 12px;
          border: 1px solid #000;
          padding: 12px 44px;
          color: #f00;
          margin-bottom: 8px;
          width: 100%;
          font-family: Outfit;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px; /* 166.667% */
          @media (max-width: 458px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}