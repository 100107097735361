.uikit__button {
  width: 152px;
  cursor: pointer;
  height: 56px;
  flex-shrink: 0;
  border-radius: 34px;
  background: #f00;
  color: #fff9f8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.14px;
  @media (max-width: 768px) {
    width: 120px;
    height: 36px;
  }
 
}
