.main {
  width: 100%;
  padding: 10px 20px 88px;
  .main__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      justify-content: center;
      row-gap: 18px;
    }
    .title {
      display: none;
    }
    .main__title {
      display: flex;
      flex-direction: column;
      .sub-title {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 23px;
        background: #fdecec;
        width: 214px;
        height: 46px;
        flex-shrink: 0;
        color: #f55556;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 100% */
        img {
          margin-left: 16px;
          width: 26px;
          height: 24px;
        }
      }

      h1 {
        margin-top: 20px;
        max-width: 627px;
        color: #2e2e2e;
        font-family: Poppins;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 88px; /* 137.5% */
        letter-spacing: -0.64px;
        @media (max-width: 648px) {
          line-height: 48px; /* 137.5% */
          font-size: 42px;
        }
        span {
          max-width: 627px;
          font-family: Poppins;
          font-size: 64px;
          font-style: normal;
          font-weight: 700;
          line-height: 88px; /* 137.5% */
          letter-spacing: -0.64px;
          color: #f00;
          font-family: Poppins;
          font-size: 64px;
          font-style: normal;
          font-weight: 500;
          line-height: 88px;
          letter-spacing: -3.2px;
          @media (max-width: 648px) {
            line-height: 48px; /* 137.5% */
            font-size: 39px;
            letter-spacing: 0px;
          }
        }
      }

      p {
        max-width: 565px;
        margin-top: 18px;
        color: #676767;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        letter-spacing: 0.09px;
      }

      .main__title-buttons {
        display: flex;
        column-gap: 37px;
        align-items: center;
        margin-top: 29px;
        @media (max-width: 450px) {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
        .main-play {
          display: flex;
          align-items: center;
          column-gap: 24px;
          border-radius: 50%;
          border: 1px solid #ffffff 1;
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 17px;
            height: 17px;
            flex-shrink: 0;
          }
        }
        span {
          color: #363636;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.2px;
          @media (max-width: 450px) {
            font-size: 17px;
          }
        }
      }
    }

    .main__image {
      padding-right: 75px;
      position: relative;
      @media (max-width: 768px) {
        display: none;
      }

      .position_button {
        position: absolute;
        top: 103px;
        right: 53px;
      }
      .product-item:nth-child(odd) {
        position: absolute;
        top: 40px;
        left: -140px;
        margin-left: 6px;

        @media (max-width: 1380px) {
          display: none;
        }
        @media (max-width: 1024px) {
          display: flex;
        }
      }
      .product-item:nth-child(even) {
        position: absolute;
        bottom: -93px;
        right: 75px;
      }
      
    }
  }
}
