.succsess {
  width: 100%;
  padding: 144px 20px 0;
  background-color: #efeef3;
  .succsess__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .succsess-img {
      position: absolute;
      right: -50px;
      top: -90px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .succsess-top {
      h2 {
        color: #f00;
        font-family: Inter;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 100% */
        letter-spacing: -1px;
        max-width: 658px;
      }

      h5 {
        max-width: 786px;
        color: #5b5a62;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        letter-spacing: -0.4px;
        margin-top: 18px;
      }

      

      .succsess-items {
        margin-top: 114px;
        display: flex;
        column-gap: 33px;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
          row-gap: 87px;
        }

       
          .succsess__container-items-active {
            color: #fff;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
            border-radius: 4px;
            background: #000;
            width: 110px;
            height: 32px;
            flex-shrink: 0;
          
        }
      }
    }

    .succsess-button {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 21px;
      margin-top: 77px;
      margin-bottom: 75px;
      color: #fff9f8;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.14px;
      width: 246px;
      height: 56px;
      flex-shrink: 0;
      border-radius: 34px;
      border: 1.5px solid #f00;
      background: #f00;
    }
  }
}
