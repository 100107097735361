.burger {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 25px;
  margin-top: 40px;
  a {
    color: #4d4d4d;
    text-align: center;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .active {
    color: #f55253;
    text-align: center;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
