.easy-export {
  width: 100%;
  padding: 127px 20px;
  @media (max-width: 458px) {
    padding: 80px 20px;
  }
  .easy-export__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    div:nth-child(1) {
      img:nth-child(1) {
        position: absolute;
        right: -50px;
        top: -220px;
        @media (max-width: 1220px) {
          display: none;
        }
      }
      img:nth-child(2) {
        position: absolute;
        left: -40px;
        top: 90px;
        @media (max-width: 1220px) {
          display: none;
        }
      }
      img:nth-child(3) {
        position: absolute;
        left: -40px;
        top: -180px;
        @media (max-width: 1220px) {
          display: none;
        }
      }
    }

    span {
      margin-bottom: 10px;
      color: #0d8772;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.72px;
    }

    h4 {
      margin-bottom: 22px;
      color: #292929;
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 120% */
      letter-spacing: -1px;
      max-width: 733px;
      flex-shrink: 0;
    }

    p {
      margin-bottom: 43px;
      color: #676767;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
      max-width: 794px;
      flex-shrink: 0;
    }

    .easy-export-items {
      display: flex;
      justify-content: space-around;
      column-gap: 43px;
      row-gap: 25px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 50px 100px -20px rgba(0, 0, 0, 0.06);
      @media (max-width: 768px) {
        flex-direction: column;
      }
      div {
        max-width: 335px;
        border-radius: 16px;
        border: 1px solid #f2f2f2;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 45px 20px;
        .easy-export-red {
          background-color: rgba(255, 0, 0, 1);
          border-radius: 50%;
          width: 80px;
          height: 80px;
          flex-shrink: 0;
          margin-bottom: 60px;
        }
        .easy-export-purple {
          border-radius: 50%;
          background-color: purple;
          width: 80px;
          height: 80px;
          flex-shrink: 0;
          margin-bottom: 60px;
        }

        img {
          margin-bottom: 55px;
        }
        span:last-child {
          color: #6f6f75;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          max-width: 255px;
        }

        p {
          margin-bottom: 14px;
          color: #292929;
          text-align: center;
          font-family: Inter;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.583px;
        }
      }
    }
  }
}
