.header {
  width: 100%;
  position: relative;
  padding: 36px 20px;
  .header__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img:nth-child(1) {
      width: 240px;
      height: 71px;
      flex-shrink: 0;
      @media (max-width: 768px) {
        width: 140px;
        height: 42px;
      }
      @media (max-width: 425px) {
        width: 120px;
        height: 40px;
      }
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        color: #4d4d4d;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-right: 56px;
        transition: all 0.5s;
        cursor: pointer;
        &:hover {
          color: #f55253;
        }
        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 25px;
      .logout {
        width: 45px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .header__buttons {
        display: flex;
        align-items: center;
        span {
          margin-right: 15px;
          border-right: 1px solid #f2f2f2;
          height: 41px;
        }
        button:nth-child(2) {
          @media (max-width: 768px) {
            display: none;
          }
        }
        img {
          width: 50px;
          height: 50px;
          display: none;
          @media (max-width: 768px) {
            display: flex;
          }
        }
      }
    }
  }
  .burger-menu {
    display: none;
    position: absolute;
    top: 100px;
    width: 100%;
    height: 100dvh;
    background-color: rgb(1, 2, 5, 0.9);
    z-index: 15;
    left: 0;
  }
}
