.footer {
  width: 100%;
  padding: 83px 20px;
  .footer__container {
    width: 100%;
    .footer__top {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
      }
      .footer__logos {
        display: flex;
        flex-direction: column;
        @media (max-width:1012px) {
          align-self: left;
          width: 100%;
        }
        img {
          width: 259px;
          height: 76px;
          flex-shrink: 0;
        }
        p {
          margin-top: 41px;
          max-width: 266px;
          color: #676767;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
        }
      }

      .footer__top-title {
        display: flex;
        column-gap: 142px;
        @media (max-width: 1024px) {
          column-gap: 10px;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        @media (max-width: 520px) {
          flex-direction: column;
        }

        div {
          display: flex;
          flex-direction: column;
          padding-top: 55px;
          @media (max-width:768px){
              padding-top: 29px;
          }
          h2 {
            color: #2e2e2e;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 111.111% */
            letter-spacing: -0.18px;
          }
          ul {
            margin-top: 41px;
            display: flex;
            flex-direction: column;
            @media (max-width:768px){
              margin-top: 21px;
            }
            li {
              a{
              color: #676767;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 40px; /* 250% */
              }
            }
          }
          span {
            margin-top: 41px;
            max-width: 191px;
            color: #888;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
            @media (max-width:768px){
              margin-top: 21px;
            }
          }
        }
      }
    }

    .footer__top-border {
      margin-top: 80px;
      margin-bottom: 30px;
      border: 1px solid #ececec;
      @media (max-width:768px) {
        margin-top: 25px;
      margin-bottom: 20px;
      }
    }

    .footer__info {
      display: flex;
      justify-content: space-between;
      @media (max-width:768px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 9px;
      }
      p {
        color: #8c8c8c;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        span {
          
          color: #8c8c8c;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 800;
          line-height: 24px;
          
        }
      }
      div {
        display: flex;
        column-gap: 25px;
        span {
          a{
          color: #747474;
          text-align: right;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          }
        }
      }
    }
    .footer__bottom {
      margin-top: 58px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1024px) {
        gap: 40px;
        justify-content: center;
        flex-wrap: wrap;
      }

      img:nth-child(2) {
        width: 80px;
        height: 90px;
      }
      img:nth-child(3) {
        width: 80px;
        height: 90px;
        width: 320px;
        height: 61.624px;
        @media (max-width: 375px) {
          width: 280px;
          height: 57px;
        }
      }
      img:nth-child(4) {
        width: 271px;
        height: 36px;
      }
      img:nth-child(5) {
        width: 111px;
        height: 89px;
      }
    }
  }
}
