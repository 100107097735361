.input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .input__container {
    display: flex;
    width: 354px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-top: 24px;
    @media(max-width: 832px) {
      width: 290px;
    }
     label {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        color: #666;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
          button {
            right: 5px;
            position: absolute;
            color: rgba(102, 102, 102, 0.8);
            text-align: right;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        
        input {
          border-radius: 12px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px 20px;
          width: 356px;
        }
      }
  }
}
