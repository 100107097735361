.subscribe {
  width: 100%;
  padding: 80px 20px;
  @media (max-width: 999px) {
    padding: 40px 20px 28px;
  }
  .subscribe__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 8px;
    position: relative;
    p {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 3.2px;
    }

    h2 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 120% */
      letter-spacing: -1px;
      @media (max-width: 999px) {
        font-size: 40px;
      }
    }

    .subscribe-input {
      width: 502px;
      height: 78px;
      border-radius: 37.5px;
      background: #fff;
      box-shadow: 0px 40px 100px -40px #120500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      position: relative;
      @media (max-width: 520px) {
        width: 319px;
        height: 56px;
      }

      input {
        margin-left: 20px;
        color: #a3a2a8;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #000;
        width: 300px;
        @media (max-width: 520px) {
          width: 158px;
        }
      }

      button {
        border-radius: 50px;
        background: #ffb200;
        width: 139px;
        height: 70px;
        margin-right: 5px;
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media (max-width: 520px) {
          width: 108px;
          height: 50px;
        }
      }
    }

    .polygon-img {
      position: absolute;
      left: -240px;
      top: -70px;
      @media (max-width: 999px) {
        display: none;
      }
    }
    img:last-child {
      position: absolute;
      right: -270px;
      top: -160px;
      @media (max-width: 999px) {
        display: none;
      }
    }
  }
}
