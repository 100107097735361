.confirmation {
    width: 100%;
    height: calc(100vh - 144px);
    padding: 140px 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: $md2) {
        height: calc(100vh - 82px);
        padding: 60px 20px;
    }
    .confirmation__container {
        max-width: 600px;
        h1 {
            color: var(--18304-bi, #222220);
            text-align: center;
            font-family: poppins;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;
            @media(max-width: $md2) {
                font-size: 30px;
            }
        }

        p {
            color: #252430;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; 
            text-align: center;
            margin-bottom: 30px;
        }

        a {
            width: 152px;
            cursor: pointer;
            height: 56px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 34px;
            background: #f00;
            color: #fff9f8;
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.14px;
            margin: 0 auto;
        }
    }
}