.support {
  width: 100%;
  background: #f00;
  margin-top: 26px;
  padding: 98px 20px;
  @media (max-width: 450px) {
    margin-top:0;
  }
  .support__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h2 {
      max-width: 627px;
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 54px; /* 135% */
      text-transform: capitalize;
      margin-bottom: 31px;
      @media (max-width: 450px) {
        margin-bottom: 14px;
      }
    }

    p {
      margin-bottom: 57px;
      max-width: 865px;
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 178.571% */
      @media (max-width: 380px) {
        background-color: red;
      }
    }

    

    .support__grid {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      column-gap: 14px;
      row-gap: 25px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .support-item {
        position: relative;
        width: 308.624px;
        height: 468.556px;

        @media (max-width: 1024px) {
          width: 230px;
          height: 500px;
          div:nth-child(2) {
            display: none;
          }
        }
        @media (max-width: 768px) {
          width: 280px;
        }
        &:hover {
          div:nth-child(2) {
            transform: rotate(-3deg) translate(20px, -7px);
            flex-shrink: 0;
            border-radius: 12px;
            background: #000;
            transition: all, 0.3s;
          }
        }
        .support-back {
          padding: 54px 31px 0px 27px;
          position: absolute;
          flex-shrink: 0;
          border-radius: 12px;
          background: #fff;
          z-index: 21;
          height: 100%;
          @media (max-width: 1024px) {
            width: 230px;
            height: 100%;
          }
          @media (max-width: 768px) {
            width: 280px;
          }

          img {
            margin-bottom: 24px;
          }

          p {
            color: #2e2e2e;
            text-align: center;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.22px;
            margin-bottom: 21px;
            background-color: transparent;
          }

          span {
            max-width: 243px;
            color: #676767;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 178.571% */
            @media (max-width: 1024px) {
              font-size: 11px;
            }
            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
        div:nth-child(2) {
          z-index: 0;
          top: 0;
          position: absolute;
          padding: 54px 31px 71px 27px;
          width: 300px;
          height: 450px;
          flex-shrink: 0;
          border-radius: 12px;
          background: black;
          width: 100%;
          height: 100%;
          @media (max-width: 1024px) {
            width: 280px;
            height: 400px;
          }
        }
      }
    }
  }
}
