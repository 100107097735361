.admin {
    width: 100%;
    padding: 80px 20px;

    .admin__container {
        max-width: 1000px;

        .admin__title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            margin-bottom: 30px;

            h1 {
                color: var(--Gray-Gray-700, #2d3748);
                font-family: Helvetica;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;

                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}

.contracts-table {
    border-radius: 6px;
    border: 1px solid #1E2128;
    background: rgba(0, 0, 0, 0.00);
    overflow: auto;
    display: block;
    max-width: 100%;
    width: 100%;

    table {
        width: 100%;
        border-collapse: collapse;
        border: none;
        appearance: none;
        min-width: 800px;

        @media(max-width: 1024px) {
            // min-width: 1000px;
        }

        thead {
            tr {
                width: 100%;

                td {
                    border-top: 1px solid #1E2128;
                    border-bottom: 1px solid #1E2128;
                    background: #f00;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    padding: 12px 4px 0;

                    @media(max-width: 1390px) {
                        font-size: 10px;
                        line-height: 14px;
                        padding-bottom: 10px;
                    }

                    @media(max-width: 1100px) {
                        font-size: 10px;
                        line-height: 14px;
                        padding-bottom: 10px;
                    }

                    &:first-child {
                        padding-left: 16px;

                        @media(max-width: 1024px) {
                            padding-left: 10px;
                        }
                    }

                    &:last-child {
                        padding-right: 16px;

                        @media(max-width: 1024px) {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

        tbody {

            tr {
                &:nth-child(even) {
                    background-color: rgb(228, 161, 161);
                }

                td {
                    color: #565D6D;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 10px 4px;

                    @media(max-width: 1390px) {
                        font-size: 10px;
                        line-height: 14px;
                    }

                    .icon-container {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        padding-right: 20px;

                        svg {
                            margin: 0;
                        }
                    }

                    button {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    svg {
                        display: block;
                        margin: 0 auto;
                    }

                    &:first-child {
                        padding-left: 16px;

                        @media(max-width: 1024px) {
                            padding-left: 10px;
                        }
                    }

                    &:last-child {
                        padding-right: 16px;

                        @media(max-width: 1024px) {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}