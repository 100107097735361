.success-button {
  display: flex;
  align-items: center;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;
  
  button {
    border-radius: 19.5px;
    border: 1px solid #e6e6e6;
    background: #fff;
    color: #414141;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    display: flex;
    width: 116px;
    height: 37px;
    padding: 10px 38px 10px 39px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}
